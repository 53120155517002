import { Flex, Link, MenuItem, Tag, Text } from "@chakra-ui/react"
import { useRouter } from "next/router"
import { useEffect } from "react"
import { useRecoilValue } from "recoil"
import { MyList } from "../../../../api-clients/mylist-api"
import { pages } from "../../../../common/constants/constants"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import useList from "../../../../common/hooks/useList"
import useUserLists from "../../../../common/hooks/useUserLists"
import { defaultListIdState } from "../../../../common/store/defaultListIdStore"
import { createDefaultList, sortUserLists } from "../../../../common/utils/my-list-utils"
import CustomLink from "../../../shared/CustomLink"
import LoadingIcon from "../../../shared/DownloadButton/LoadingIcon"
import { CArrowRight } from "../../../shared/Icons"

type Props = {
  isOpen: boolean
  onClose: () => void
}

const MAX_USER_LISTS_SHOWN = 4

const MyListsLoggedIn = ({ isOpen, onClose }: Props) => {
  const { t } = useBrandedTranslation()
  const router = useRouter()
  const defaultListId = useRecoilValue(defaultListIdState)

  /* Custom hook to get the user lists data */
  const {
    userListsData,
    createList,
    createdListData,
    isUserLoggedIn,
    isLoading: isUserListsLoading,
    error: listsError,
  } = useUserLists()

  /* Custom hook to get the list data */
  const { listData, isLoading: isListLoading } = useList(defaultListId)

  /* Show loader in place of list quantity if the user has a list and we wait for its data or we create the list before redirection */
  const isLoading = isListLoading || isUserListsLoading

  const defaultList = { ...listData, id: null }

  const hasUserList = !!userListsData?.results?.length
  /* If there are user lists returned, get them in sorted order.
    Otherwise display only the default list or a placeholder default list. */
  const userLists = hasUserList ? sortUserLists(userListsData?.results, MAX_USER_LISTS_SHOWN) : [defaultList]

  useEffect(() => {
    if (isOpen && createdListData) {
      const { id: newListId } = createdListData

      /* Redirect to the newly created list page */
      router.push(`/${pages.LIST}/${newListId}`)

      /* Close the My Lists menu */
      onClose()

      return
    }

    if (isOpen && listsError) {
      // TODO (nan) Handle the error with a Toast when the component is created
      console.log("CREATE MY LIST ERROR: ", listsError)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createdListData])

  const handleCreateList = async (e: React.MouseEvent<HTMLButtonElement>) => {
    /* If no user lists, create a new list */
    if (!hasUserList) {
      e.preventDefault()

      createDefaultList({
        createList,
        isUserLoggedIn,
        anonymousList: listData,
        defaultName: t("wishlist-default-name"),
      })

      return
    }

    onClose()
  }

  return (
    <Flex flexDirection="column">
      {userLists.map((list: MyList) => {
        return (
          <CustomLink href={`/${pages.LIST}/${list.id ?? ""}`} key={list.id ?? "default"}>
            <MenuItem
              as="a"
              alignItems="center"
              justifyContent="space-between"
              color="black"
              onClick={handleCreateList}
              px={{ base: "2", md: "2" }}
              h={{ base: "11", md: "10" }}
            >
              <Text fontSize="sm" fontWeight="medium" noOfLines={1} wordBreak="break-all">
                {list.name ?? t("wishlist-default-name")}
              </Text>
              {isOpen && isLoading ? (
                <LoadingIcon />
              ) : (
                <Tag borderRadius="full" bgColor="gray.200" h="6">
                  <Text color="gray.900" fontSize="xs" fontWeight="medium">
                    {list.totalArticles}
                  </Text>
                </Tag>
              )}
            </MenuItem>
          </CustomLink>
        )
      })}

      <Flex
        alignItems="center"
        gap="2"
        w="max-content"
        px="2"
        mt="2"
        h={{ base: "11", sm: "10" }}
        color="brand.500"
        _hover={{
          textDecoration: "underline",
          textDecorationColor: "brand.700",
          color: "brand.700",
          cursor: "pointer",
        }}
        _active={{ textDecorationColor: "brand.800", color: "brand.800" }}
        onClick={onClose}
      >
        <CArrowRight />
        <CustomLink href={`/${pages.LISTS}`}>
          <Link>
            <Text fontSize="sm" fontWeight="medium">
              {t("search-view-all")}
            </Text>
          </Link>
        </CustomLink>
      </Flex>
    </Flex>
  )
}

export default MyListsLoggedIn
