/* tslint:disable */
/* eslint-disable */
/**
 * PDF Request
 * Use the PDF Request API to obtain product data sheets (PDF) as well as catalogs and sample catalogs (PDF) for the Geberit online catalog. While this API is the consumer-facing service for acquiring the mentioned PDFs, our PDF HTML API and PDF Generation API are used to generate the actual PDFs and their content.
 *
 * The version of the OpenAPI document: v1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios"
import globalAxios from "axios"
import type { Configuration } from "./configuration"
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from "./base"
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setApiKeyToObject,
  setSearchParams,
  toPathString,
} from "./common"
// @ts-ignore
import { BaseAPI, BASE_PATH } from "./base"

/**
 *
 * @export
 * @interface AdditionalInformationListModel
 */
export interface AdditionalInformationListModel {
  /**
   *
   * @type {Array<AdditionalInformationModel>}
   * @memberof AdditionalInformationListModel
   */
  list?: Array<AdditionalInformationModel>
}
/**
 *
 * @export
 * @interface AdditionalInformationModel
 */
export interface AdditionalInformationModel {
  /**
   *
   * @type {Array<string>}
   * @memberof AdditionalInformationModel
   */
  items?: Array<string>
  /**
   *
   * @type {string}
   * @memberof AdditionalInformationModel
   * @deprecated
   */
  replaceStatus?: AdditionalInformationModelReplaceStatusEnum
}

export const AdditionalInformationModelReplaceStatusEnum = {
  By: "REPLACED_BY",
  For: "REPLACED_FOR",
} as const

export type AdditionalInformationModelReplaceStatusEnum =
  (typeof AdditionalInformationModelReplaceStatusEnum)[keyof typeof AdditionalInformationModelReplaceStatusEnum]

/**
 *
 * @export
 * @interface AppModel
 */
export interface AppModel {
  /**
   *
   * @type {string}
   * @memberof AppModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof AppModel
   */
  description?: string
  /**
   *
   * @type {Array<AppStore>}
   * @memberof AppModel
   */
  stores?: Array<AppStore>
  /**
   *
   * @type {Array<ProductImageModel>}
   * @memberof AppModel
   */
  images?: Array<ProductImageModel>
}
/**
 *
 * @export
 * @interface AppStore
 */
export interface AppStore {
  /**
   *
   * @type {string}
   * @memberof AppStore
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof AppStore
   */
  name?: AppStoreNameEnum
}

export const AppStoreNameEnum = {
  Itunes: "itunes",
  GooglePlay: "googlePlay",
} as const

export type AppStoreNameEnum = (typeof AppStoreNameEnum)[keyof typeof AppStoreNameEnum]

/**
 *
 * @export
 * @interface AppsListModel
 */
export interface AppsListModel {
  /**
   *
   * @type {Array<AppModel>}
   * @memberof AppsListModel
   */
  list?: Array<AppModel>
}
/**
 *
 * @export
 * @interface ArticleListModel
 */
export interface ArticleListModel {
  /**
   *
   * @type {Array<ArticleModel>}
   * @memberof ArticleListModel
   */
  articlesList?: Array<ArticleModel>
  /**
   *
   * @type {Array<AttributeModel>}
   * @memberof ArticleListModel
   */
  attributesList?: Array<AttributeModel>
  /**
   *
   * @type {number}
   * @memberof ArticleListModel
   */
  articlesListSize?: number
}
/**
 *
 * @export
 * @interface ArticleModel
 */
export interface ArticleModel {
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  articleId?: string
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  idInternal?: string
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  eanCode?: string
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  description?: string
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ArticleModel
   */
  gallery?: ProductGalleryModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ArticleModel
   */
  cadDrawings?: SimpleAssetListModel
  /**
   *
   * @type {Array<AttributeValueModel>}
   * @memberof ArticleModel
   */
  attributeValues?: Array<AttributeValueModel>
  /**
   *
   * @type {Array<EtimValueModel>}
   * @memberof ArticleModel
   */
  etimValues?: Array<EtimValueModel>
  /**
   *
   * @type {ProductStateModel}
   * @memberof ArticleModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {string}
   * @memberof ArticleModel
   */
  tenderTextProductId?: string
}
/**
 *
 * @export
 * @interface AttributeModel
 */
export interface AttributeModel {
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof AttributeModel
   */
  name?: string
}
/**
 *
 * @export
 * @interface AttributeValueModel
 */
export interface AttributeValueModel {
  /**
   *
   * @type {string}
   * @memberof AttributeValueModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof AttributeValueModel
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof AttributeValueModel
   */
  rawValue?: string
  /**
   *
   * @type {Array<UnitModel>}
   * @memberof AttributeValueModel
   */
  unit?: Array<UnitModel>
}
/**
 *
 * @export
 * @interface BimAssetsModel
 */
export interface BimAssetsModel {
  /**
   *
   * @type {Array<ProductImageModel>}
   * @memberof BimAssetsModel
   */
  list?: Array<ProductImageModel>
}
/**
 * Contains catalog data to include in the generated catalog PDF.
 * @export
 * @interface CatalogRequest
 */
export interface CatalogRequest {
  /**
   * The catalog title as defined by the user.
   * @type {string}
   * @memberof CatalogRequest
   */
  title?: string
  /**
   * The catalog creator\'s name as defined by the user.
   * @type {string}
   * @memberof CatalogRequest
   */
  creator?: string
  /**
   * The catalog\'s description as defined by the user.
   * @type {string}
   * @memberof CatalogRequest
   */
  description?: string
  /**
   * The name of the wishlist associated with the catalog. Wishlist names are created by the user in the online catalog.
   * @type {string}
   * @memberof CatalogRequest
   */
  wishlistName?: string
  /**
   * The alternative template in which to render the catalog PDF.  Currently, the only alternative template is Pluvia (define as `catalog-pdf-pluvia` in your request), which adds a new page to the PDF with one image and two columns of text. You can define content for the Pluvia template via the `additionalData` attribute in the request body.
   * @type {string}
   * @memberof CatalogRequest
   */
  templateName?: string
  /**
   * A list of article numbers whose respective product data sheets to include in the catalog.
   * @type {Array<string>}
   * @memberof CatalogRequest
   */
  articleSkuList: Array<string>
  /**
   * Contains additional data that you can specify when you use an alternative template via the `templateName` attribute in the request body.  Currently, the only alternative template is Pluvia, which adds a new page to the catalog with an image and two columns of text. To provide the Pluvia template with data, it needs additional data as defined in this attribute\'s example.
   * @type {{ [key: string]: object; }}
   * @memberof CatalogRequest
   */
  additionalData?: { [key: string]: object }
}
/**
 *
 * @export
 * @interface CategoryModel
 */
export interface CategoryModel {
  /**
   *
   * @type {string}
   * @memberof CategoryModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof CategoryModel
   */
  name?: string
}
/**
 *
 * @export
 * @interface DeliveryInfoListModel
 */
export interface DeliveryInfoListModel {
  /**
   *
   * @type {Array<LabelWithSequenceModel>}
   * @memberof DeliveryInfoListModel
   */
  included?: Array<LabelWithSequenceModel>
  /**
   *
   * @type {Array<LabelWithSequenceModel>}
   * @memberof DeliveryInfoListModel
   */
  notIncluded?: Array<LabelWithSequenceModel>
  /**
   *
   * @type {boolean}
   * @memberof DeliveryInfoListModel
   */
  hasScopeOfDelivery?: boolean
}
/**
 *
 * @export
 * @interface DocumentListModel
 */
export interface DocumentListModel {
  /**
   *
   * @type {Array<DocumentModel>}
   * @memberof DocumentListModel
   */
  list?: Array<DocumentModel>
}
/**
 *
 * @export
 * @interface DocumentModel
 */
export interface DocumentModel {
  /**
   *
   * @type {string}
   * @memberof DocumentModel
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof DocumentModel
   */
  contentType?: string
  /**
   *
   * @type {string}
   * @memberof DocumentModel
   */
  url?: string
  /**
   *
   * @type {Set<string>}
   * @memberof DocumentModel
   */
  languages?: Set<string>
}
/**
 *
 * @export
 * @interface EtimFeatureModel
 */
export interface EtimFeatureModel {
  /**
   *
   * @type {string}
   * @memberof EtimFeatureModel
   */
  name?: string
  /**
   *
   * @type {Array<string>}
   * @memberof EtimFeatureModel
   */
  values?: Array<string>
  /**
   *
   * @type {string}
   * @memberof EtimFeatureModel
   */
  unit?: string
  /**
   *
   * @type {string}
   * @memberof EtimFeatureModel
   */
  details?: string
}
/**
 *
 * @export
 * @interface EtimValueModel
 */
export interface EtimValueModel {
  /**
   *
   * @type {string}
   * @memberof EtimValueModel
   */
  details?: string
  /**
   *
   * @type {Array<string>}
   * @memberof EtimValueModel
   */
  version?: Array<string>
  /**
   *
   * @type {string}
   * @memberof EtimValueModel
   */
  classification?: string
  /**
   *
   * @type {Array<EtimFeatureModel>}
   * @memberof EtimValueModel
   */
  features?: Array<EtimFeatureModel>
}
/**
 *
 * @export
 * @interface LabelWithSequenceListModel
 */
export interface LabelWithSequenceListModel {
  /**
   *
   * @type {Array<LabelWithSequenceModel>}
   * @memberof LabelWithSequenceListModel
   */
  list?: Array<LabelWithSequenceModel>
}
/**
 *
 * @export
 * @interface LabelWithSequenceModel
 */
export interface LabelWithSequenceModel {
  /**
   *
   * @type {string}
   * @memberof LabelWithSequenceModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof LabelWithSequenceModel
   */
  key?: string
  /**
   *
   * @type {number}
   * @memberof LabelWithSequenceModel
   */
  sequence?: number
}
/**
 * Contains the URL to the requested PDF.
 * @export
 * @interface PdfUrlResponse
 */
export interface PdfUrlResponse {
  /**
   * The URL to the requested PDF.
   * @type {string}
   * @memberof PdfUrlResponse
   */
  url?: string
}
/**
 *
 * @export
 * @interface ProductGalleryModel
 */
export interface ProductGalleryModel {
  /**
   *
   * @type {string}
   * @memberof ProductGalleryModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ProductGalleryModel
   */
  description?: string
  /**
   *
   * @type {Array<ProductImageModel>}
   * @memberof ProductGalleryModel
   */
  list?: Array<ProductImageModel>
}
/**
 *
 * @export
 * @interface ProductImageModel
 */
export interface ProductImageModel {
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  thumbnailSmall?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  thumbnail?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  small?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  medium?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  large?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  original?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  orientation?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof ProductImageModel
   */
  eps?: string
}
/**
 * Contains the JSON product data (the entire product schema) of all products included in the sample catalog. For example, you can retrieve the corresponding data via the Product API.
 * @export
 * @interface ProductModel
 */
export interface ProductModel {
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  productId?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  brandName?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  series?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  productBrand?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  system?: string
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  bleDevice?: string
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ProductModel
   */
  gallery?: ProductGalleryModel
  /**
   *
   * @type {ProductVariantModel}
   * @memberof ProductModel
   */
  variant?: ProductVariantModel
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {ArticleListModel}
   * @memberof ProductModel
   */
  articles?: ArticleListModel
  /**
   *
   * @type {TechnicalAttributesListModel}
   * @memberof ProductModel
   */
  technicalDataList?: TechnicalAttributesListModel
  /**
   *
   * @type {AdditionalInformationListModel}
   * @memberof ProductModel
   */
  additionalInformation?: AdditionalInformationListModel
  /**
   *
   * @type {DeliveryInfoListModel}
   * @memberof ProductModel
   */
  scopeOfDelivery?: DeliveryInfoListModel
  /**
   *
   * @type {LabelWithSequenceListModel}
   * @memberof ProductModel
   */
  characteristics?: LabelWithSequenceListModel
  /**
   *
   * @type {LabelWithSequenceListModel}
   * @memberof ProductModel
   */
  applicationPurposes?: LabelWithSequenceListModel
  /**
   *
   * @type {ProductTileListModel}
   * @memberof ProductModel
   */
  accessories?: ProductTileListModel
  /**
   *
   * @type {ProductTileListModel}
   * @memberof ProductModel
   */
  canBeCombinedWith?: ProductTileListModel
  /**
   *
   * @type {SparePartOverviewListModel}
   * @memberof ProductModel
   */
  spareParts?: SparePartOverviewListModel
  /**
   *
   * @type {DocumentListModel}
   * @memberof ProductModel
   */
  documents?: DocumentListModel
  /**
   *
   * @type {VideoListModel}
   * @memberof ProductModel
   */
  videos?: VideoListModel
  /**
   *
   * @type {AppsListModel}
   * @memberof ProductModel
   */
  apps?: AppsListModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ProductModel
   */
  accessoriesOverviews?: SimpleAssetListModel
  /**
   *
   * @type {SimpleAssetListModel}
   * @memberof ProductModel
   */
  intendedUseOverviews?: SimpleAssetListModel
  /**
   *
   * @type {Set<CategoryModel>}
   * @memberof ProductModel
   */
  categories?: Set<CategoryModel>
  /**
   *
   * @type {string}
   * @memberof ProductModel
   */
  tenderTextProductId?: string
  /**
   *
   * @type {BimAssetsModel}
   * @memberof ProductModel
   */
  bimAssets?: BimAssetsModel
}
/**
 *
 * @export
 * @interface ProductStateModel
 */
export interface ProductStateModel {
  /**
   *
   * @type {string}
   * @memberof ProductStateModel
   */
  from?: string
  /**
   *
   * @type {string}
   * @memberof ProductStateModel
   */
  indicator?: ProductStateModelIndicatorEnum
  /**
   *
   * @type {boolean}
   * @memberof ProductStateModel
   */
  bimRelevant?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductStateModel
   */
  bimReady?: boolean
  /**
   *
   * @type {string}
   * @memberof ProductStateModel
   */
  replacementFor?: string
  /**
   *
   * @type {string}
   * @memberof ProductStateModel
   */
  replacedBy?: string
  /**
   *
   * @type {boolean}
   * @memberof ProductStateModel
   */
  buyable?: boolean
  /**
   *
   * @type {boolean}
   * @memberof ProductStateModel
   */
  orderRelated?: boolean
}

export const ProductStateModelIndicatorEnum = {
  New: "NEW",
  Phaseout: "PHASEOUT",
} as const

export type ProductStateModelIndicatorEnum =
  (typeof ProductStateModelIndicatorEnum)[keyof typeof ProductStateModelIndicatorEnum]

/**
 *
 * @export
 * @interface ProductTileListModel
 */
export interface ProductTileListModel {
  /**
   *
   * @type {Array<ProductTileModel>}
   * @memberof ProductTileListModel
   */
  list?: Array<ProductTileModel>
}
/**
 *
 * @export
 * @interface ProductTileModel
 */
export interface ProductTileModel {
  /**
   *
   * @type {string}
   * @memberof ProductTileModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof ProductTileModel
   */
  name?: string
  /**
   *
   * @type {number}
   * @memberof ProductTileModel
   */
  numberArticles?: number
  /**
   *
   * @type {ProductStateModel}
   * @memberof ProductTileModel
   */
  state?: ProductStateModel
  /**
   *
   * @type {ProductGalleryModel}
   * @memberof ProductTileModel
   */
  gallery?: ProductGalleryModel
  /**
   *
   * @type {string}
   * @memberof ProductTileModel
   */
  sku?: string
  /**
   *
   * @type {string}
   * @memberof ProductTileModel
   */
  seriesName?: string
}
/**
 *
 * @export
 * @interface ProductVariantModel
 */
export interface ProductVariantModel {
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  sku?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  url?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  image?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  price?: string
  /**
   *
   * @type {string}
   * @memberof ProductVariantModel
   */
  priceOld?: string
}
/**
 * Contains information about the product categories to which the products added to the sample catalog belong.
 * @export
 * @interface SampleCatalogCategory
 */
export interface SampleCatalogCategory {
  /**
   * The name of the category.
   * @type {string}
   * @memberof SampleCatalogCategory
   */
  name?: string
  /**
   * The category\'s slogan.
   * @type {string}
   * @memberof SampleCatalogCategory
   */
  slogan?: string
  /**
   * The category\'s image.
   * @type {string}
   * @memberof SampleCatalogCategory
   */
  image?: string
  /**
   * An `array` of product IDs that belong to this product category to include in the sample catalog.
   * @type {Array<string>}
   * @memberof SampleCatalogCategory
   */
  products: Array<string>
}
/**
 * Contains information about the sample catalog\'s groups as defined by the user.
 * @export
 * @interface SampleCatalogGroup
 */
export interface SampleCatalogGroup {
  /**
   * The group\'s title as defined by the user.
   * @type {string}
   * @memberof SampleCatalogGroup
   */
  title?: string
  /**
   * The group\'s description as defined by the user.
   * @type {string}
   * @memberof SampleCatalogGroup
   */
  description?: string
  /**
   * The group\'s image as defined by the user, `Base64` encoded. Images should be quadratic and may not exceed 1MB size.
   * @type {object}
   * @memberof SampleCatalogGroup
   */
  image?: object
  /**
   * An array of `SampleCatalogCategory` objects. Contains information about the product categories included in this group.
   * @type {Array<SampleCatalogCategory>}
   * @memberof SampleCatalogGroup
   */
  categories?: Array<SampleCatalogCategory>
}
/**
 * Contains catalog data to include in the generated sample catalog PDF.
 * @export
 * @interface SampleCatalogRequest
 */
export interface SampleCatalogRequest {
  /**
   * The catalog title as defined by the user.
   * @type {string}
   * @memberof SampleCatalogRequest
   */
  title?: string
  /**
   * The catalog\'s description as defined by the user.
   * @type {string}
   * @memberof SampleCatalogRequest
   */
  description?: string
  /**
   * An array of `SampleCatalogGroup` objects. Contains information about the sample catalog\'s groups.
   * @type {Array<SampleCatalogGroup>}
   * @memberof SampleCatalogRequest
   */
  groups: Array<SampleCatalogGroup>
  /**
   * Contains the JSON product data (the entire product schema) of all products included in the sample catalog. For example, you can retrieve the corresponding data via the Product API.
   * @type {{ [key: string]: ProductModel; }}
   * @memberof SampleCatalogRequest
   */
  products: { [key: string]: ProductModel }
  /**
   * A list of article numbers whose respective product data sheets to include in the sample catalog.
   * @type {Array<string>}
   * @memberof SampleCatalogRequest
   */
  articleSkuList: Array<string>
  /**
   * The email address to send the sample catalog\'s download link when the catalog is created on the online catalog website.  Please note, however, that the PDF Request API returns the PDF file of the sample catalog or its URL directly and does not send any emails.
   * @type {string}
   * @memberof SampleCatalogRequest
   */
  email?: string
}
/**
 *
 * @export
 * @interface SimpleAssetListModel
 */
export interface SimpleAssetListModel {
  /**
   *
   * @type {Array<SimpleAssetModel>}
   * @memberof SimpleAssetListModel
   */
  list?: Array<SimpleAssetModel>
}
/**
 *
 * @export
 * @interface SimpleAssetModel
 */
export interface SimpleAssetModel {
  /**
   *
   * @type {string}
   * @memberof SimpleAssetModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SimpleAssetModel
   */
  url?: string
}
/**
 *
 * @export
 * @interface SparePartArticleModel
 */
export interface SparePartArticleModel {
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  idInternal?: string
  /**
   *
   * @type {SparePartArticleStateModel}
   * @memberof SparePartArticleModel
   */
  state?: SparePartArticleStateModel
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  type?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  position?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleModel
   */
  remarks?: string
  /**
   *
   * @type {Array<AttributeValueModel>}
   * @memberof SparePartArticleModel
   */
  attributeValues?: Array<AttributeValueModel>
}
/**
 *
 * @export
 * @interface SparePartArticleStateModel
 */
export interface SparePartArticleStateModel {
  /**
   *
   * @type {boolean}
   * @memberof SparePartArticleStateModel
   */
  available?: boolean
  /**
   *
   * @type {string}
   * @memberof SparePartArticleStateModel
   */
  replacedBy?: string
  /**
   *
   * @type {string}
   * @memberof SparePartArticleStateModel
   */
  replacementFor?: string
}
/**
 *
 * @export
 * @interface SparePartArticlesListModel
 */
export interface SparePartArticlesListModel {
  /**
   *
   * @type {Array<SparePartArticleModel>}
   * @memberof SparePartArticlesListModel
   */
  sparePartArticlesList?: Array<SparePartArticleModel>
  /**
   *
   * @type {Array<AttributeModel>}
   * @memberof SparePartArticlesListModel
   */
  attributesList?: Array<AttributeModel>
}
/**
 *
 * @export
 * @interface SparePartOverviewListModel
 */
export interface SparePartOverviewListModel {
  /**
   *
   * @type {Array<SparePartOverviewModel>}
   * @memberof SparePartOverviewListModel
   */
  list?: Array<SparePartOverviewModel>
}
/**
 *
 * @export
 * @interface SparePartOverviewModel
 */
export interface SparePartOverviewModel {
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  productId?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  brandNames?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  series?: string
  /**
   *
   * @type {string}
   * @memberof SparePartOverviewModel
   */
  types?: string
  /**
   *
   * @type {number}
   * @memberof SparePartOverviewModel
   */
  manufactureFrom?: number
  /**
   *
   * @type {number}
   * @memberof SparePartOverviewModel
   */
  manufactureTo?: number
  /**
   *
   * @type {ProductImageModel}
   * @memberof SparePartOverviewModel
   */
  image?: ProductImageModel
  /**
   *
   * @type {SparePartArticlesListModel}
   * @memberof SparePartOverviewModel
   */
  sparePartArticles?: SparePartArticlesListModel
}
/**
 *
 * @export
 * @interface TechnicalAttributesListModel
 */
export interface TechnicalAttributesListModel {
  /**
   *
   * @type {Array<TechnicalAttributesModel>}
   * @memberof TechnicalAttributesListModel
   */
  list?: Array<TechnicalAttributesModel>
}
/**
 *
 * @export
 * @interface TechnicalAttributesModel
 */
export interface TechnicalAttributesModel {
  /**
   *
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  key?: string
  /**
   *
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  value?: string
  /**
   *
   * @type {string}
   * @memberof TechnicalAttributesModel
   */
  rawValue?: string
  /**
   *
   * @type {number}
   * @memberof TechnicalAttributesModel
   */
  sequence?: number
  /**
   *
   * @type {Array<UnitModel>}
   * @memberof TechnicalAttributesModel
   */
  unit?: Array<UnitModel>
}
/**
 *
 * @export
 * @interface UnitModel
 */
export interface UnitModel {
  /**
   *
   * @type {string}
   * @memberof UnitModel
   */
  encoding?: string
  /**
   *
   * @type {string}
   * @memberof UnitModel
   */
  value?: string
}
/**
 *
 * @export
 * @interface VideoListModel
 */
export interface VideoListModel {
  /**
   *
   * @type {Array<VideoModel>}
   * @memberof VideoListModel
   */
  list?: Array<VideoModel>
}
/**
 *
 * @export
 * @interface VideoModel
 */
export interface VideoModel {
  /**
   *
   * @type {string}
   * @memberof VideoModel
   */
  youtubeID?: string
}

/**
 * CatalogPDFApi - axios parameter creator
 * @export
 */
export const CatalogPDFApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve the respective branded catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get branded catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedCatalogPdf: async (
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedCatalogPdf", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedCatalogPdf", "brand", brand)
      const localVarPath = `/{locale}/{brand}/catalog/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective branded catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create branded catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedCatalogPdfUrl: async (
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedCatalogPdfUrl", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedCatalogPdfUrl", "brand", brand)
      const localVarPath = `/{locale}/{brand}/catalog/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective branded sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get branded sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSampleCatalogPdf: async (
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedSampleCatalogPdf", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedSampleCatalogPdf", "brand", brand)
      const localVarPath = `/{locale}/{brand}/sample-catalog/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(sampleCatalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective branded sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create branded sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSampleCatalogPdfUrl: async (
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedSampleCatalogPdfUrl", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedSampleCatalogPdfUrl", "brand", brand)
      const localVarPath = `/{locale}/{brand}/sample-catalog/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(sampleCatalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and catalog data, such as title, creator, and articles in the request body to retrieve the respective catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogPdf: async (
      locale: string,
      catalogRequest?: CatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getCatalogPdf", "locale", locale)
      const localVarPath = `/{locale}/catalog/pdf`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and the catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogPdfUrl: async (
      locale: string,
      catalogRequest?: CatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getCatalogPdfUrl", "locale", locale)
      const localVarPath = `/{locale}/catalog/pdf/url`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(catalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSampleCatalogPdf: async (
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSampleCatalogPdf", "locale", locale)
      const localVarPath = `/{locale}/sample-catalog/pdf`.replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(sampleCatalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSampleCatalogPdfUrl: async (
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSampleCatalogPdfUrl", "locale", locale)
      const localVarPath = `/{locale}/sample-catalog/pdf/url`.replace(
        `{${"locale"}}`,
        encodeURIComponent(String(locale))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(sampleCatalogRequest, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * CatalogPDFApi - functional programming interface
 * @export
 */
export const CatalogPDFApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CatalogPDFApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve the respective branded catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get branded catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedCatalogPdf(
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedCatalogPdf(
        locale,
        brand,
        catalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective branded catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create branded catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedCatalogPdfUrl(
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedCatalogPdfUrl(
        locale,
        brand,
        catalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective branded sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get branded sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedSampleCatalogPdf(
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedSampleCatalogPdf(
        locale,
        brand,
        sampleCatalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective branded sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create branded sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedSampleCatalogPdfUrl(
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedSampleCatalogPdfUrl(
        locale,
        brand,
        sampleCatalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and catalog data, such as title, creator, and articles in the request body to retrieve the respective catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatalogPdf(
      locale: string,
      catalogRequest?: CatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogPdf(locale, catalogRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and the catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCatalogPdfUrl(
      locale: string,
      catalogRequest?: CatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCatalogPdfUrl(locale, catalogRequest, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSampleCatalogPdf(
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleCatalogPdf(
        locale,
        sampleCatalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSampleCatalogPdfUrl(
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSampleCatalogPdfUrl(
        locale,
        sampleCatalogRequest,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * CatalogPDFApi - factory interface
 * @export
 */
export const CatalogPDFApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = CatalogPDFApiFp(configuration)
  return {
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve the respective branded catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get branded catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedCatalogPdf(
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .getBrandedCatalogPdf(locale, brand, catalogRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective branded catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create branded catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedCatalogPdfUrl(
      locale: string,
      brand: string,
      catalogRequest?: CatalogRequest,
      options?: any
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedCatalogPdfUrl(locale, brand, catalogRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective branded sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get branded sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSampleCatalogPdf(
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .getBrandedSampleCatalogPdf(locale, brand, sampleCatalogRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective branded sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create branded sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSampleCatalogPdfUrl(
      locale: string,
      brand: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: any
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedSampleCatalogPdfUrl(locale, brand, sampleCatalogRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and catalog data, such as title, creator, and articles in the request body to retrieve the respective catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create and get catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogPdf(locale: string, catalogRequest?: CatalogRequest, options?: any): AxiosPromise<void> {
      return localVarFp.getCatalogPdf(locale, catalogRequest, options).then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and the catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
     * @summary Create catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCatalogPdfUrl(locale: string, catalogRequest?: CatalogRequest, options?: any): AxiosPromise<PdfUrlResponse> {
      return localVarFp.getCatalogPdfUrl(locale, catalogRequest, options).then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create and get sample catalog.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSampleCatalogPdf(
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .getSampleCatalogPdf(locale, sampleCatalogRequest, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
     * @summary Create sample catalog and get URL.
     * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSampleCatalogPdfUrl(
      locale: string,
      sampleCatalogRequest?: SampleCatalogRequest,
      options?: any
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getSampleCatalogPdfUrl(locale, sampleCatalogRequest, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * CatalogPDFApi - object-oriented interface
 * @export
 * @class CatalogPDFApi
 * @extends {BaseAPI}
 */
export class CatalogPDFApi extends BaseAPI {
  /**
   * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve the respective branded catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
   * @summary Create and get branded catalog.
   * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
   * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getBrandedCatalogPdf(
    locale: string,
    brand: string,
    catalogRequest?: CatalogRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getBrandedCatalogPdf(locale, brand, catalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale, brand, and catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective branded catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
   * @summary Create branded catalog and get URL.
   * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
   * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getBrandedCatalogPdfUrl(
    locale: string,
    brand: string,
    catalogRequest?: CatalogRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getBrandedCatalogPdfUrl(locale, brand, catalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective branded sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
   * @summary Create and get branded sample catalog.
   * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
   * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getBrandedSampleCatalogPdf(
    locale: string,
    brand: string,
    sampleCatalogRequest?: SampleCatalogRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getBrandedSampleCatalogPdf(locale, brand, sampleCatalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale, brand, and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective branded sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
   * @summary Create branded sample catalog and get URL.
   * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The catalog\&#39;s branding. The PDF Request API supports all available brands.
   * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getBrandedSampleCatalogPdfUrl(
    locale: string,
    brand: string,
    sampleCatalogRequest?: SampleCatalogRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getBrandedSampleCatalogPdfUrl(locale, brand, sampleCatalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and catalog data, such as title, creator, and articles in the request body to retrieve the respective catalog PDF.  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
   * @summary Create and get catalog.
   * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getCatalogPdf(locale: string, catalogRequest?: CatalogRequest, options?: AxiosRequestConfig) {
    return CatalogPDFApiFp(this.configuration)
      .getCatalogPdf(locale, catalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and the catalog data, such as title, creator, and articles in the request body to retrieve a JSON object containing the URL to the respective catalog (PDF).  Each catalog comprises a cover sheet featuring the specified catalog data, followed by the relevant product data sheets.
   * @summary Create catalog and get URL.
   * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {CatalogRequest} [catalogRequest] Define the data to include in the catalog as JSON using the &#x60;CatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getCatalogPdfUrl(locale: string, catalogRequest?: CatalogRequest, options?: AxiosRequestConfig) {
    return CatalogPDFApiFp(this.configuration)
      .getCatalogPdfUrl(locale, catalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve the respective sample catalog PDF.  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
   * @summary Create and get sample catalog.
   * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getSampleCatalogPdf(
    locale: string,
    sampleCatalogRequest?: SampleCatalogRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getSampleCatalogPdf(locale, sampleCatalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a locale and sample catalog data, such as title, groups, categories, and articles in the request body to retrieve a JSON object containing the URL to the respective sample catalog (PDF).  A sample catalog is an extended version of the catalog PDF in which the user can create groups to categorize their wanted products. As a result, the sample catalog lists the respective product data sheets in the defined groups, sorted by product categories.
   * @summary Create sample catalog and get URL.
   * @param {string} locale The catalog\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {SampleCatalogRequest} [sampleCatalogRequest] Define the data to include in the sample catalog as JSON using the &#x60;SampleCatalogRequest&#x60; schema. For more information about each attribute, see the corresponding schema documentation.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CatalogPDFApi
   */
  public getSampleCatalogPdfUrl(
    locale: string,
    sampleCatalogRequest?: SampleCatalogRequest,
    options?: AxiosRequestConfig
  ) {
    return CatalogPDFApiFp(this.configuration)
      .getSampleCatalogPdfUrl(locale, sampleCatalogRequest, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ProductPDFApi - axios parameter creator
 * @export
 */
export const ProductPDFApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a product ID, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfByKey: async (
      locale: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductPdfByKey", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductPdfByKey", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getBrandedProductPdfByKey", "key", key)
      const localVarPath = `/{locale}/{brand}/products/{key}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfBySku: async (
      locale: string,
      brand: string,
      sku: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductPdfBySku", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductPdfBySku", "brand", brand)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getBrandedProductPdfBySku", "sku", sku)
      const localVarPath = `/{locale}/{brand}/articles/{sku}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a product ID, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfUrlByKey: async (
      locale: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlByKey", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlByKey", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlByKey", "key", key)
      const localVarPath = `/{locale}/{brand}/products/{key}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfUrlBySku: async (
      locale: string,
      brand: string,
      sku: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlBySku", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlBySku", "brand", brand)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getBrandedProductPdfUrlBySku", "sku", sku)
      const localVarPath = `/{locale}/{brand}/articles/{sku}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a product ID and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfByKey: async (locale: string, key: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductPdfByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductPdfByKey", "key", key)
      const localVarPath = `/{locale}/products/{key}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfBySku: async (locale: string, sku: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductPdfBySku", "locale", locale)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getProductPdfBySku", "sku", sku)
      const localVarPath = `/{locale}/articles/{sku}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a product ID and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfUrlByKey: async (
      locale: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductPdfUrlByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getProductPdfUrlByKey", "key", key)
      const localVarPath = `/{locale}/products/{key}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify an article number and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfUrlBySku: async (
      locale: string,
      sku: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getProductPdfUrlBySku", "locale", locale)
      // verify required parameter 'sku' is not null or undefined
      assertParamExists("getProductPdfUrlBySku", "sku", sku)
      const localVarPath = `/{locale}/articles/{sku}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"sku"}}`, encodeURIComponent(String(sku)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ProductPDFApi - functional programming interface
 * @export
 */
export const ProductPDFApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ProductPDFApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a product ID, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductPdfByKey(
      locale: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductPdfByKey(locale, brand, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductPdfBySku(
      locale: string,
      brand: string,
      sku: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductPdfBySku(locale, brand, sku, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a product ID, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductPdfUrlByKey(
      locale: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductPdfUrlByKey(
        locale,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedProductPdfUrlBySku(
      locale: string,
      brand: string,
      sku: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedProductPdfUrlBySku(
        locale,
        brand,
        sku,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a product ID and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductPdfByKey(
      locale: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPdfByKey(locale, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductPdfBySku(
      locale: string,
      sku: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPdfBySku(locale, sku, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a product ID and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductPdfUrlByKey(
      locale: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPdfUrlByKey(locale, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify an article number and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductPdfUrlBySku(
      locale: string,
      sku: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductPdfUrlBySku(locale, sku, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ProductPDFApi - factory interface
 * @export
 */
export const ProductPDFApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ProductPDFApiFp(configuration)
  return {
    /**
     * Specify a product ID, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfByKey(locale: string, brand: string, key: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .getBrandedProductPdfByKey(locale, brand, key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfBySku(locale: string, brand: string, sku: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .getBrandedProductPdfBySku(locale, brand, sku, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a product ID, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfUrlByKey(
      locale: string,
      brand: string,
      key: string,
      options?: any
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedProductPdfUrlByKey(locale, brand, key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get branded product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedProductPdfUrlBySku(
      locale: string,
      brand: string,
      sku: string,
      options?: any
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedProductPdfUrlBySku(locale, brand, sku, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a product ID and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfByKey(locale: string, key: string, options?: any): AxiosPromise<void> {
      return localVarFp.getProductPdfByKey(locale, key, options).then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfBySku(locale: string, sku: string, options?: any): AxiosPromise<void> {
      return localVarFp.getProductPdfBySku(locale, sku, options).then((request) => request(axios, basePath))
    },
    /**
     * Specify a product ID and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by ID.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The product\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfUrlByKey(locale: string, key: string, options?: any): AxiosPromise<PdfUrlResponse> {
      return localVarFp.getProductPdfUrlByKey(locale, key, options).then((request) => request(axios, basePath))
    },
    /**
     * Specify an article number and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
     * @summary Get product data sheet URL by article number.
     * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductPdfUrlBySku(locale: string, sku: string, options?: any): AxiosPromise<PdfUrlResponse> {
      return localVarFp.getProductPdfUrlBySku(locale, sku, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * ProductPDFApi - object-oriented interface
 * @export
 * @class ProductPDFApi
 * @extends {BaseAPI}
 */
export class ProductPDFApi extends BaseAPI {
  /**
   * Specify a product ID, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get branded product data sheet by ID.
   * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
   * @param {string} key The product\&#39;s ID (formerly called key).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductPdfByKey(locale: string, brand: string, key: string, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductPdfByKey(locale, brand, key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number, locale, and brand to retrieve the respective branded product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get branded product data sheet by article number.
   * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
   * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductPdfBySku(locale: string, brand: string, sku: string, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductPdfBySku(locale, brand, sku, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a product ID, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get branded product data sheet URL by ID.
   * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
   * @param {string} key The product\&#39;s ID (formerly called key).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductPdfUrlByKey(locale: string, brand: string, key: string, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductPdfUrlByKey(locale, brand, key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number, locale, and brand to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  The branded product data sheet details the brand logo, application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get branded product data sheet URL by article number.
   * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The product\&#39;s brand. The PDF Request API supports all available brands.
   * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getBrandedProductPdfUrlBySku(locale: string, brand: string, sku: string, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getBrandedProductPdfUrlBySku(locale, brand, sku, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a product ID and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get product data sheet by ID.
   * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} key The product\&#39;s ID (formerly called key).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductPdfByKey(locale: string, key: string, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getProductPdfByKey(locale, key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number and locale to retrieve the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get product data sheet by article number.
   * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductPdfBySku(locale: string, sku: string, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getProductPdfBySku(locale, sku, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a product ID and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get product data sheet URL by ID.
   * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} key The product\&#39;s ID (formerly called key).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductPdfUrlByKey(locale: string, key: string, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getProductPdfUrlByKey(locale, key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify an article number and locale to retrieve a JSON object containing the URL to the respective product data sheet (PDF).  Product data sheets detail a product\'s application purposes, characteristics, technical data, scope of delivery, articles, accessories, and other product-related information.
   * @summary Get product data sheet URL by article number.
   * @param {string} locale The product\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} sku The article number (also called SKU) whose associated product data sheet you want to retrieve.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ProductPDFApi
   */
  public getProductPdfUrlBySku(locale: string, sku: string, options?: AxiosRequestConfig) {
    return ProductPDFApiFp(this.configuration)
      .getProductPdfUrlBySku(locale, sku, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SparePartsPDFApi - axios parameter creator
 * @export
 */
export const SparePartsPDFApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Specify a spare part ID, locale, and brand to retrieve the respective branded spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSparePartPdfByKey: async (
      locale: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedSparePartPdfByKey", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedSparePartPdfByKey", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getBrandedSparePartPdfByKey", "key", key)
      const localVarPath = `/{locale}/{brand}/spare-parts/{key}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a spare parts ID, locale, and brand to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSparePartPdfUrlByKey: async (
      locale: string,
      brand: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getBrandedSparePartPdfUrlByKey", "locale", locale)
      // verify required parameter 'brand' is not null or undefined
      assertParamExists("getBrandedSparePartPdfUrlByKey", "brand", brand)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getBrandedSparePartPdfUrlByKey", "key", key)
      const localVarPath = `/{locale}/{brand}/spare-parts/{key}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"brand"}}`, encodeURIComponent(String(brand)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a spare part ID and locale to retrieve the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartPdfByKey: async (
      locale: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSparePartPdfByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartPdfByKey", "key", key)
      const localVarPath = `/{locale}/spare-parts/{key}/pdf`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     * Specify a spare part ID and locale to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartPdfUrlByKey: async (
      locale: string,
      key: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locale' is not null or undefined
      assertParamExists("getSparePartPdfUrlByKey", "locale", locale)
      // verify required parameter 'key' is not null or undefined
      assertParamExists("getSparePartPdfUrlByKey", "key", key)
      const localVarPath = `/{locale}/spare-parts/{key}/pdf/url`
        .replace(`{${"locale"}}`, encodeURIComponent(String(locale)))
        .replace(`{${"key"}}`, encodeURIComponent(String(key)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      // authentication apiKeyHeader required
      await setApiKeyToObject(localVarHeaderParameter, "Ocp-Apim-Subscription-Key", configuration)

      // authentication apiKeyQuery required
      await setApiKeyToObject(localVarQueryParameter, "subscription-key", configuration)

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SparePartsPDFApi - functional programming interface
 * @export
 */
export const SparePartsPDFApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SparePartsPDFApiAxiosParamCreator(configuration)
  return {
    /**
     * Specify a spare part ID, locale, and brand to retrieve the respective branded spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedSparePartPdfByKey(
      locale: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedSparePartPdfByKey(locale, brand, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a spare parts ID, locale, and brand to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBrandedSparePartPdfUrlByKey(
      locale: string,
      brand: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBrandedSparePartPdfUrlByKey(
        locale,
        brand,
        key,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a spare part ID and locale to retrieve the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartPdfByKey(
      locale: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartPdfByKey(locale, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     * Specify a spare part ID and locale to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getSparePartPdfUrlByKey(
      locale: string,
      key: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfUrlResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getSparePartPdfUrlByKey(locale, key, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SparePartsPDFApi - factory interface
 * @export
 */
export const SparePartsPDFApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SparePartsPDFApiFp(configuration)
  return {
    /**
     * Specify a spare part ID, locale, and brand to retrieve the respective branded spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSparePartPdfByKey(locale: string, brand: string, key: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .getBrandedSparePartPdfByKey(locale, brand, key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a spare parts ID, locale, and brand to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get branded spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBrandedSparePartPdfUrlByKey(
      locale: string,
      brand: string,
      key: string,
      options?: any
    ): AxiosPromise<PdfUrlResponse> {
      return localVarFp
        .getBrandedSparePartPdfUrlByKey(locale, brand, key, options)
        .then((request) => request(axios, basePath))
    },
    /**
     * Specify a spare part ID and locale to retrieve the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartPdfByKey(locale: string, key: string, options?: any): AxiosPromise<void> {
      return localVarFp.getSparePartPdfByKey(locale, key, options).then((request) => request(axios, basePath))
    },
    /**
     * Specify a spare part ID and locale to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
     * @summary Get spare parts data sheet URL by ID.
     * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
     * @param {string} key The spare part\&#39;s ID (formerly called key).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getSparePartPdfUrlByKey(locale: string, key: string, options?: any): AxiosPromise<PdfUrlResponse> {
      return localVarFp.getSparePartPdfUrlByKey(locale, key, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * SparePartsPDFApi - object-oriented interface
 * @export
 * @class SparePartsPDFApi
 * @extends {BaseAPI}
 */
export class SparePartsPDFApi extends BaseAPI {
  /**
   * Specify a spare part ID, locale, and brand to retrieve the respective branded spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
   * @summary Get branded spare parts data sheet by ID.
   * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
   * @param {string} key The spare part\&#39;s ID (formerly called key).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsPDFApi
   */
  public getBrandedSparePartPdfByKey(locale: string, brand: string, key: string, options?: AxiosRequestConfig) {
    return SparePartsPDFApiFp(this.configuration)
      .getBrandedSparePartPdfByKey(locale, brand, key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a spare parts ID, locale, and brand to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The branded spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
   * @summary Get branded spare parts data sheet URL by ID.
   * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} brand The spare part\&#39;s brand. The PDF Request API supports all available brands.
   * @param {string} key The spare part\&#39;s ID (formerly called key).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsPDFApi
   */
  public getBrandedSparePartPdfUrlByKey(locale: string, brand: string, key: string, options?: AxiosRequestConfig) {
    return SparePartsPDFApiFp(this.configuration)
      .getBrandedSparePartPdfUrlByKey(locale, brand, key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a spare part ID and locale to retrieve the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
   * @summary Get spare parts data sheet by ID.
   * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} key The spare part\&#39;s ID (formerly called key).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsPDFApi
   */
  public getSparePartPdfByKey(locale: string, key: string, options?: AxiosRequestConfig) {
    return SparePartsPDFApiFp(this.configuration)
      .getSparePartPdfByKey(locale, key, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   * Specify a spare part ID and locale to retrieve a JSON object containing the URL to the respective spare part data sheet (PDF).  The spare part data sheet details a spare part\'s characteristics, articles, and other relevant information.
   * @summary Get spare parts data sheet URL by ID.
   * @param {string} locale The spare part\&#39;s locale. The PDF Request API supports all locales available in the Geberit catalog.  You can find a list of available locales in the [Localization user guide](/locales).
   * @param {string} key The spare part\&#39;s ID (formerly called key).
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SparePartsPDFApi
   */
  public getSparePartPdfUrlByKey(locale: string, key: string, options?: AxiosRequestConfig) {
    return SparePartsPDFApiFp(this.configuration)
      .getSparePartPdfUrlByKey(locale, key, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
