import { Collapse, Flex, Menu, Text } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../../../common/hooks/useBrandedTranslation"
import { useAuth } from "../../../../../common/hooks/useGigya"
import MyListsLoggedIn from "../MyListsLoggedIn"
import MyListsLoggedOut from "../MyListsLoggedOut"

type Props = {
  isActive: boolean
  handleCloseMenu: () => void
}

const MobileMyListsMenu = ({ isActive, handleCloseMenu }: Props) => {
  const { t } = useBrandedTranslation()
  const { accountInfo } = useAuth()
  const isUserLoggedIn = !!accountInfo?.userId

  return (
    <Collapse in={isActive} animateOpacity unmountOnExit>
      <Flex flexDirection="column">
        <Text my="2" color="gray.700" fontSize="sm" paddingY="1.5" borderBottom="1px solid" borderColor="gray.300">
          {t("acc-myLists")}
        </Text>
        <Menu isOpen={isActive}>
          {isUserLoggedIn ? (
            <MyListsLoggedIn isOpen={isActive} onClose={handleCloseMenu} />
          ) : (
            <MyListsLoggedOut isOpen={isActive} onClose={handleCloseMenu} />
          )}
        </Menu>
      </Flex>
    </Collapse>
  )
}

export default MobileMyListsMenu
