import { Button, Menu, MenuButton, MenuList, Text } from "@chakra-ui/react"
import { useBrandedTranslation } from "../../../../common/hooks/useBrandedTranslation"
import { useAuth } from "../../../../common/hooks/useGigya"
import { CLists } from "../../../shared/Icons"
import MyListsLoggedIn from "./MyListsLoggedIn"
import MyListsLoggedOut from "./MyListsLoggedOut"

const MyListsButton = () => {
  const { t } = useBrandedTranslation()
  const { accountInfo } = useAuth()
  const isUserLoggedIn = !!accountInfo?.userId

  return (
    <Menu
      gutter={0}
      autoSelect={false}
      closeOnSelect={false}
      flip={false}
      placement="bottom-end"
      isLazy // Render the menu items onOpen of filter for faster SRP initial load time
    >
      {({ isOpen, onClose }) => (
        <>
          <MenuButton
            as={Button}
            leftIcon={<CLists />}
            textAlign="left"
            iconSpacing="0"
            px="2"
            variant="dropdown"
            color="gray.900"
            aria-label="Open My Lists"
          >
            <Text as="span" fontSize="xs" fontWeight="normal" ml="2" mt="1">
              {t("header-logged-myList")}
            </Text>
          </MenuButton>
          <MenuList w="86" py="2" boxShadow="xl">
            {isUserLoggedIn ? (
              <MyListsLoggedIn isOpen={isOpen} onClose={onClose} />
            ) : (
              <MyListsLoggedOut isOpen={isOpen} onClose={onClose} />
            )}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default MyListsButton
